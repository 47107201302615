@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Lotuss Smart HL';
    src: url('./assets/fonts/LotussSmartHL-Light.otf') format('opentype');
    font-weight: 300;
  }
  
  @font-face {
    font-family: 'Lotuss Smart HL';
    src: url('./assets/fonts/LotussSmartHL-Regular.otf') format('opentype');
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'Lotuss Smart HL';
    src: url('./assets/fonts/LotussSmartHL-Medium.otf') format('opentype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Lotuss Smart HL';
    src: url('./assets/fonts/LotussSmartHL-Bold.otf') format('opentype');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Lotuss Smart HL';
    src: url('./assets/fonts/LotussSmartHL-ExtraBold.otf') format('opentype');
    font-weight: 800;
  }

  * {
    font-family: 'Lotuss Smart HL';
    font-weight: 500;
  }
}

/* @layer utilities {
  .font-normal {
      font-variation-settings: 'wght' 400;
  }
  .font-medium {
      font-variation-settings: 'wght' 500;
  }
  .font-bold {
      font-variation-settings: 'wght' 800;
  }
} */




