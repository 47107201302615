.offers-list-container, .error-page-container {
    height: calc(100% - 186px);
}

.hide-scroll {
    overflow-y: hidden;
}

.dummy-padding-top {
    height: 134px;
    width: 100%;
}

.dummy-padding-bottom {
    height: 104px;
    width: 100%;
}

.offers {
    position: relative;
    overflow-x: hidden;
}

.offer-details {
    position: absolute;
    top: 0px;
    z-index: 99999;
    background-color: white;
    transition: left .3s linear;
    width: 100vw;
    height: 100%;
    overflow-y: auto;
}

.disabled-animation {
    transition: unset !important;
}

.hide {
    left: 100%;
}

.show {
    left: 0%;
}